var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bg_box" }),
    _c(
      "div",
      { staticClass: "centerbox" },
      [
        _c("headbox", {
          attrs: {
            title: "设置",
            bgColor: "#fff",
            isborder: "true",
            isbackperson: true
          }
        }),
        _c("div", { staticClass: "flex-between bgwhite" }, [
          _c("div", { staticClass: "flex" }, [
            _vm.userInfo.auth == "UNPOST"
              ? _c("div", { staticClass: "imgbox" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/images/no_cerhead.png") }
                  })
                ])
              : _vm._e(),
            _vm.userInfo.auth == "POST"
              ? _c("div", { staticClass: "imgbox" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/images/no_cerhead.png") }
                  })
                ])
              : _vm._e(),
            _vm.userInfo.auth == "DONE"
              ? _c(
                  "div",
                  { staticClass: "imgbox" },
                  [
                    _vm.userInfo.avatar
                      ? [_c("img", { attrs: { src: _vm.userInfo.avatar } })]
                      : [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/people.png")
                            }
                          })
                        ]
                  ],
                  2
                )
              : _vm._e(),
            _c("div", { staticClass: "phonebox" }, [
              _vm._v(_vm._s(_vm.userInfo.phone))
            ])
          ]),
          _vm.userInfo.auth == "UNPOST" || _vm.userInfo.auth == "FAIL"
            ? _c(
                "div",
                { staticClass: "statusbox", on: { click: _vm.gocer } },
                [
                  _vm._v(" 请认证"),
                  _c("i", { staticClass: "el-icon-arrow-right" })
                ]
              )
            : _vm.userInfo.auth == "POST"
            ? _c("div", { staticClass: "statusbox" }, [
                _vm._v(" 认证中"),
                _c("i", { staticClass: "el-icon-arrow-right" })
              ])
            : !_vm.userInfo.hasOwnProperty("sign")
            ? _c(
                "div",
                {
                  staticClass: "statusbox overcer",
                  on: { click: _vm.authgraph }
                },
                [
                  _vm._v(" 待签名"),
                  _c("i", { staticClass: "el-icon-arrow-right" })
                ]
              )
            : !_vm.userInfo.hasOwnProperty("faceVerify")
            ? _c(
                "div",
                { staticClass: "statusbox overcer", on: { click: _vm.face } },
                [
                  _vm._v(" 待人脸认证"),
                  _c("i", { staticClass: "el-icon-arrow-right" })
                ]
              )
            : _vm.userInfo.auth == "DONE" &&
              _vm.userInfo.hasOwnProperty("faceVerify")
            ? _c("div", { staticClass: "statusbox overcer" }, [
                _vm._v(" 已认证 ")
              ])
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "flex-between  bgwhite", on: { click: _vm.repas } },
          [_c("div", [_vm._v("修改密码")]), _vm._m(0)]
        ),
        _c(
          "div",
          { staticClass: "flex-between  bgwhite", on: { click: _vm.suspends } },
          [_c("div", [_vm._v("悬浮窗报价")]), _vm._m(1)]
        ),
        _c(
          "div",
          { staticClass: "flex-between  bgwhite", on: { click: _vm.address } },
          [_c("div", [_vm._v("地址管理")]), _vm._m(2)]
        ),
        _vm.userInfo.auth == "DONE" && _vm.userInfo.hasOwnProperty("sign")
          ? _c(
              "div",
              {
                staticClass: "flex-between  bgwhite",
                on: { click: _vm.goAgreements }
              },
              [_c("div", [_vm._v("相关协议")]), _vm._m(3)]
            )
          : _vm._e(),
        _c("div", { staticClass: "loginout", on: { click: _vm.loginout } }, [
          _vm._v("退出登录")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "el-icon-arrow-right" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "el-icon-arrow-right" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "el-icon-arrow-right" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "el-icon-arrow-right" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }